.Messages {
  display: flex;
  flex-direction: row;
  height: 500px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

  .Messages-contacts {
    width: 250px;
    border-right: 1px solid #e0e0e0;
    overflow-y: auto;

    .Messages-contact {
      padding: 16px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f5f5f5;
      }

      &.active {
        background-color: #e3f2fd;
      }

      .contact-name {
        font-weight: 500;
        margin-bottom: 4px;
      }

      .contact-email {
        font-size: 0.85em;
        color: #666;
      }
    }
  }

  .Messages-chat {
    flex: 1;
    display: flex;
    flex-direction: column;

    .Messages-history {
      flex: 1;
      padding: 16px;
      overflow-y: auto;

      .loading-message,
      .no-messages {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #666;
        font-style: italic;
      }

      .message {
        margin-bottom: 24px;
        max-width: 70%;
        
        &.received {
          margin-right: auto;
          
          .message-content {
            background-color: #f5f5f5;
            border-radius: 12px 12px 12px 0;
          }
        }

        &.sent {
          margin-left: auto;
          
          .message-content {
            background-color: #e3f2fd;
            border-radius: 12px 12px 0 12px;
          }

          .message-header {
            .message-time {
              margin-left: 8px;
            }
          }
        }

        .message-header {
          display: flex;
          align-items: baseline;
          margin-bottom: 6px;
          font-size: 0.85em;
          padding: 0 4px;
          width: 100%;
          
          .sender-name {
            font-weight: 500;
            margin-right: 8px;
            order: 1;
          }
          
          .message-title {
            font-style: italic;
            color: #666;
            margin: 0 8px;
            font-weight: normal;
            order: 2;
            flex-grow: 1;
          }
          
          .message-time {
            color: #666;
            order: 3;
            white-space: nowrap;
          }
        }

        .message-content {
          padding: 12px 16px;
          line-height: 1.4;
          
          .message-text {
            white-space: pre-wrap;
          }
        }
      }
    }

    .Messages-input {
      padding: 16px;
      border-top: 1px solid #e0e0e0;

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .input-group {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        input, textarea {
          padding: 8px 12px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          font-family: inherit;

          &:focus {
            outline: none;
            border-color: #2196f3;
          }
        }

        input {
          height: 36px;
        }

        textarea {
          flex: 1;
          min-height: 80px;
          resize: none;
        }

        .button-container {
          display: flex;
          justify-content: flex-end;
          
          button {
            min-width: 100px;
            
            &.loading {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
} 