@import "../../variables";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px $backgroundLighter inset !important;
}

.CompanySelfRegistration {
  margin-left: 96px;
  margin-bottom: $marginXLarge;

  @media screen and (max-width: $breakpointXs+px) {
    margin-left: 0;
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: $colorPrimary;
  }

  .Title {
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    color: $colorTextWhite;
    margin-bottom: 44px;
  }

  .Subtitle {
    font-size: 20px;
    font-weight: 500;
    margin-top: 14px;
    margin-bottom: 24px;
    color: $colorTextWhite;
  }

  .CreateButton {
      font-size: 18px;

      @media screen and (max-width: 460px) {
        width: 120px;
      }
  }

  .EditField {
    padding: 0;

    @media screen and (max-width: 460px) {
      width: 300px;
    }

    &-name {
      margin-bottom: 8px;
      color: $colorTextWhite;
    }

    .EditFieldString, .EditFieldDropdown .EditFieldDropdown-placeholder {
      background-color: $backgroundLighter;
    }
  }

  .FormField {
    margin-bottom: 30px;
  }

  .TermsAndConditions {
    display: flex;

    svg path {
      fill: $backgroundLighter;
    }
    @media screen and (max-width: 460px) {
      width: 300px;
    }

    p {
      margin: 2px;
    }
  }

  .UserPhone {
    display: flex;
    flex-direction: row;

    .EditField:first-child {
      input {
        width: 80px;
      }
    }

    .EditField:last-child {
      width: 100%;

      p {
        height: 19px;
      }
    }

    @media screen and (max-width: $breakpointSm+px) {
      flex-direction: column;
    }
  }

  .on-dark-background {
    background-color: $backgroundLighter;
  }
}
