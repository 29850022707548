@import "../../variables";

.mainContent {
  display: flex;
  flex-direction: column;
  gap: $marginLarge;
  padding: $marginLarge;
}

.topPanels {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $marginLarge;
}

.documentsPanel {
  width: 100%;
}

.panel {
  background: white;
  padding: $marginNormal;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0px $colorLightGray;

  h2 {
    margin: 0;
    padding-bottom: 8px;
    padding-top: 8px;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .placeholderSection {
    padding: $marginLarge;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    text-align: center;

    .placeholderIcon {
      font-size: 2rem;
      margin-bottom: $marginSmall;
      opacity: 0.5;
    }

    p {
      margin: 0;
      color: $colorGray;
    }
  }
}

.overviewPanel {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $marginLarge;
  background-color: $backgroundLighter;

  .placeholderSection {
    margin-top: $marginLarge;
    padding: $marginLarge;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    text-align: center;

    .placeholderIcon {
      font-size: 2rem;
      margin-bottom: $marginSmall;
      opacity: 0.5;
    }

    h4 {
      margin: 0 0 $marginXSmall;
      color: $colorGray;
    }

    p {
      margin: 0;
      color: $colorGray;
    }
  }
}

.overviewContent {
  padding: $marginSmall;

  h2 {
    margin-top: 0;
    margin-bottom: $marginLarge;
    font-size: 1.2em;
    font-weight: 500;
  }

  .description {
    margin-bottom: 24px;

    h3 {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 500;
    }

    p {
      margin-bottom: 16px;
      line-height: 1.5;
    }
  }

  .limitedAccessMessage {
    margin-bottom: $marginLarge;
    padding: $marginSmall;
    background: rgba($colorPrimary, 0.05);
    border-radius: 4px;
    
    p {
      margin: 0;
      color: $colorPrimary;
    }
  }
}

.mandate {
  display: flex;
  justify-content: space-between;
  padding: $marginSmall 0;
  border-bottom: 1px solid $colorLightGray;

  &:last-child {
    border-bottom: none;
  }
}

.mandateInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mandateName {
  font-weight: 500;
}

.mandateId {
  color: $colorGray;
  font-size: 0.9em;
}

.mandateAmount {
  font-weight: 500;
}

.field {
  margin-bottom: $marginNormal;

  label {
    font-size: 0.8em;
    color: $colorGray;
    margin-bottom: $marginXSmall;
    display: block;
  }
} 

.notYetImplemented {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: $marginLarge;
  height: calc(100% - 45px);
  background: rgba($colorPrimary, 0.05);
  border-radius: 4px;
  text-align: center;
}