@import "../../variables";

$fieldSizeLong: 280px;
$fieldSizeShort: 200px;

.MyInvestments {
    .section {
        margin: 14px $marginXLarge;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            gap: $marginSmall;

            @media screen and (max-width: $breakpointLg) {
                flex-direction: column;
                align-items: flex-start;
            }

            .Search {
                margin-right: $marginNormal;

                .Search-input {
                    width: 100%;
                    box-shadow: 1px 1px 2px 0 $colorLightGray;
                }
            }

            .EditField {
                margin-right: $marginNormal;
                width: $fieldSizeShort;
                padding: 0;
            }
            @media screen and (max-width: $breakpointLg) {
                .Search, .EditField {
                    width: $fieldSizeLong;
                }
            }
            @media screen and (max-width: $breakpointSm) {
                .Search, .EditField {
                    width: $fieldSizeShort;
                }
            }
        }
    }

    .EditField {
        width: 260px;
        padding: 0;

        .MuiOutlinedInput-root {
            height: 46px;
            box-shadow: 1px 1px 2px 0 $colorLightGray;
        }

        .MuiOutlinedInput-input {
            font-size: 14px;
        }

        .EditFieldDropdown-placeholder {
            font-size: 14px !important;
            background-color: white;
        }
    }

    .MuiTableCell-root:last-child {
        text-align: right;
        width: 100px;
    }
}